import React from "react"
import Layout from "../modules/layout"
import SEO from "../modules/seo"
import { graphql } from "gatsby"
import { Container } from "react-bootstrap"

const NotFoundPage = ({ data: { allContentfulSiteSettings }}) => {
  const siteSettings = allContentfulSiteSettings.edges[0].node
  return (
    <Layout siteSettings={siteSettings}>
      <SEO title="404: Not found" />
      <Container fluid>
        <div className="row">
          <div className="col-12">
            <h1>NOT FOUND</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query siteSettings404 {
    allContentfulSiteSettings {
      edges {
          node {
              siteName
              siteDescription
              siteAuthor
              copyright
              navigation {
                  slug
                  name
              }
              logo {
                fixed(width: 260, height: 80) {
                  base64
                  width
                  height
                  src
                  srcSet
                }
              }
          }
      }
    }
  }
`

export default NotFoundPage
